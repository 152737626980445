<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
/*import Multiselect from "vue-multiselect";
import { MazPhoneNumberInput } from "maz-ui";
import moment from "moment"; */
//import { format } from "date-fns";
//import image from "@/assets/images/users/user.png";

export default {
  page: {
    title: "Détails signals",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Détails Alertes",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Détails signals",
          active: true,
        },
      ],
       ordersData: [
        
       ],
      showPassword: false,
      phoneNumber: "",
      thenDay: "",
      DetailAlertesData:[],
       totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100,250,500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "itinéraire", label:"trajet" },
        { key: "profil", sortable: true, label: "Profil" },
        { key: "contact", sortable: true, label: "Personne à contacter" },
        { key: "signaledBy", sortable: true, label: "Signalé par" },
        { key: "date", sortable: true , label:"Date"},
        { key: "propos", label:"Propos"},
       
      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      showDelete: false,
      showEdit: false,
      showdisable: false,
    };
  },
  async mounted(){
    const DetailAlertes = await apiRequest(
      "GET",
      "admin/signals",
      undefined,
      false
    );

    if(DetailAlertes && DetailAlertes.data){
      console.log("Detail Alertes:", DetailAlertes.data)
      const DetailAlerteTable = DetailAlertes.data.map((details)=>{
        return{
          itinéraire: details.trajet.lieuDepart + " >> " + details.trajet.lieuArrivee,
          profil: details.signaler.personne.lastname + " "+ details.signaler.personne.firstname,
          signaledBy: details.user.personne.lastname + " "+ details.user.personne.firstname,
          contact: details.signaler.personne.tel ? details.signaler.personne.tel : " ",
          date:new Date(details.datePublication).toLocaleString("fr-FR"),
          propos: details.contenu
        };
      });
      this.DetailAlertesData = DetailAlerteTable;
    }

    this.totalRows = this.DetailAlerteTableData.length;
  },

  methods: {
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      //console.log("this.$v:", this.$v);
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      //this.create();
    },

    tooltipForm() {
      this.submitform = true;
      //this.submitted = true;
      this.formSubmit();
      this.$v.$touch();
    },

    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <h5>Nombre Details signalés : {{this.totalRows}} </h5>
            </div>
            <form>
              <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active"></a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    class="table-centered"
                    :items="DetailAlertesData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                  >
                   
                    <template v-slot:cell(action)>
                      <a
                        href="javascript:void(0);"
                        class=" text-primary"
                        v-b-tooltip.hover
                        @click="showEdit = true"
                        title="Editer"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-success"
                        v-b-tooltip.hover
                        @click="showdisable = true"
                        title="Désactiver"
                      >
                        <i class="mdi mdi-close font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-danger"
                        @click="showDelete =true"
                        v-b-tooltip.hover
                        title="supprimer"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i> 
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
#showPassword {
  width: 35px;
  height: 35px;
}
</style>
